.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  opacity: 0.8;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 0.3rem;
  border-radius: 5px;

  .pagination-item {
    margin: 0.4rem;
    width: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
  }

  .active {
    background: white;
  }

  .icons {
    cursor: pointer;
    margin: 0 1rem 0;
  }
}
