.filters-container {
  justify-content: space-around;
  display: flex;
  height: 100%;
  padding: 1rem;
  width: 90%;
  margin-bottom: 2vh;
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  padding: 2vh;
  opacity: 1;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(50px);
  z-index: 1;

  .instruction {
    font-weight: 550;
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
  }

  .daterange-box {
    display: flex;
    flex-direction: column;
  }

  .paid-box {
    display: flex;
    flex-direction: column;
  }

  .regions-box,
  .paid-box,
  .daterange-box {
    display: flex;
    flex-direction: column;
    width: 18rem;
    height: auto;

    .css-b62m3t-container {
      width: 18rem;
    }

    .css-1s2u09g-control {
      background: white;
      border-radius: 10px;
      border: none;
      padding: 1vh;
      opacity: 1;
      box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
      backdrop-filter: blur(100px);
      -webkit-backdrop-filter: blur(50px);
      height: 7vh;
    }

    .css-1pahdxg-control {
      background: white;
      border-radius: 10px;
      border: none;
      padding: 1vh;
      opacity: 1;
      box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
      backdrop-filter: blur(100px);
      -webkit-backdrop-filter: blur(50px);
      height: 7vh;
    }
  }

  .react-daterange-picker__wrapper {
    background: white;
    border-radius: 10px;
    border: none;
    padding: 2vh;
    opacity: 1;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(50px);
    height: 7vh;
  }

  .filters-box {
    background: transparent
      linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 10px;
    padding: 2vh;
    opacity: 1;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(50px);
    width: 350px;
    max-width: 100%;
    height: 350px;
    padding: 2vh;
    overflow-y: auto;
    z-index: 999;

    .selected-filter {
      background: white;
    }

    li {
      list-style: none;
      padding: 8px 12px;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background: #efefef;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .filters-container {
    flex-direction: column;
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .filters-container {
    width: 90%;

    .daterange-box {
      width: auto;
    }
  }
  .regions-box,
  .paid-box,
  .daterange-box {
    margin-top: 2vh;
  }

  // .filters-container {
  //   .regions-box,
  //   .paid-box {
  //     .css-1pahdxg-control,
  //     .css-1s2u09g-control,
  //     .react-daterange-picker__wrapper {
  //       height: 10vh;
  //     }
  //   }
  //   .react-daterange-picker__wrapper {
  //     height: 10vh;
  //   }
  // }
}
