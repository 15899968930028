.grid {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loading-container-home {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}

.cards {
  align-items: center;
  text-decoration: none;
  text-align: center;
  color: #301a4b;
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  border: 2px solid #ffffff99;
  border-radius: 10px;
  opacity: 0.8;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  min-width: 236px;
}

.ads {
  align-items: center;
  text-decoration: none;
  text-align: center;
  color: #301a4b;
  background: #caebfb;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  opacity: 1;
  min-width: 236px;
}

.row {
  justify-content: center;
}

.card-slot {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  width: 236px;
  height: 280px;
  margin: 1vh;
}

.advertisement-slot {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  width: 14.75rem;
  height: 36rem;
  margin: 1vh;
}

.gridicons {
  margin-top: 3vh;
}

.h5 {
  text-align: center;
  font-weight: 600;
}

.h6 {
  text-align: center;
  font-weight: 600;
}

.grid {
  margin-bottom: 5vh;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .grid {
    flex-direction: column;
  }

  .card-slot {
    margin-top: 2vh;
  }

  .advertisement-slot {
    height: 23.75rem;
  }

  #reklama1 {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .grid {
    flex-direction: column;
    margin-top: 5rem;
  }

  .card-slot {
    margin-top: 2vh;
  }

  .advertisement-slot {
    height: 23.75rem;
  }

  #reklama1 {
    display: none;
  }
}
