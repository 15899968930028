.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-offers {
  text-align: center;
}

.page-item.active,
.page-link {
  z-index: -999;
}

.offers {
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  padding: 2vh;
  opacity: 1;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(50px);
  // z-index: -999;

  .sort-box {
    display: flex;
    justify-content: space-around;

    .sort-date,
    .sort-price {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: large;

      .sort-icons {
        margin-left: 1vw;
        opacity: 0.3;
        cursor: pointer;
      }

      .sort-icons-active {
        margin-left: 1vw;
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  button {
    background: #2861c9;
    color: white;
    outline: none;
    border: none;
    align-self: center;
    font-weight: 600;
    font-size: 19px;
    border-radius: 5px;
    transition-duration: 0.2s;
    padding: 1rem;
  }

  button:hover {
    background: transparent;
    color: #1a1f23;
  }
}

.offer-row {
  text-align: center;
  margin: 3vh;
}

.categories {
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: flex;
  justify-content: space-evenly;
}

.offer-image {
  img {
    width: 200px;
    height: 66.67px;
  }
}

.modal {
  ul {
    list-style: none;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 100%;
  margin-bottom: 2vh;
  margin-top: 2vh;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .offers {
    margin-top: 3vh;
    // z-index: -999;
  }

  .filters-container {
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;

    .filters-box {
      margin-top: 2vh;
    }
  }

  .regions-box {
    margin-top: 2rem;
  }

  .react-daterange-picker__wrapper {
    width: 70vw;
  }

  .css-1s2u09g-control {
    width: 70vw;
  }
  .css-1pahdxg-control {
    width: 70vw;
  }

  .daterange-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .offer-row {
    flex-direction: column;
  }
}
