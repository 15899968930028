.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 78vh;
  margin-bottom: 3vh;
  flex-direction: column;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding: 3vh;
  margin-top: -5vh;
  color: #3d4a54;
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  opacity: 0.8;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(50px);

  h2 {
    align-self: center;
    font-weight: 700;
    margin-top: 6vh;
    margin-bottom: 6vh;
  }

  .login-label {
    font-weight: bold;
    font-size: 0.9rem;
  }

  .login-input {
    color: white;
    background: #3d4a54;
  }

  ::placeholder {
    color: #ffffff;
  }

  .forgot-text {
    margin-bottom: 2vh;
    margin-top: -1vh;
    text-decoration: underline;
    color: #3d4a54;
    font-weight: bold;
    cursor: pointer;
  }

  .register-text {
    align-self: center;
    margin-top: 2vh;
    color: #3d4a54;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  button {
    background: #1a1f23;
    outline: none;
    border: none;
    width: 40%;
    align-self: center;
    font-weight: 600;
    font-size: 19px;
    border-radius: 5px;
    transition-duration: 0.2s;
  }

  button:hover {
    background: transparent;
    color: #1a1f23;
  }
}

.login-oval {
  margin-top: 2vh;
}

.register-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3vw;
  color: #3d4a54;
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  padding: 3vh;
  opacity: 0.9;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(50px);

  .form-label {
    font-weight: 700;
  }

  .login-input {
    color: white;
    background: #3d4a54;
  }

  ::placeholder {
    color: white;
  }

  .register-text {
    margin-top: 1vh;
    color: #3d4a54;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
}

.buttons {
  button {
    background: #3d4a54;
    color: white;
    padding: 1vh;
    outline: none;
    border: none;
    align-self: center;
    font-weight: 600;
    font-size: 19px;
    border-radius: 5px;
    transition-duration: 0.2s;
  }

  button:hover {
    background: transparent;
    color: #1a1f23;
  }
}

.forgot-button {
  margin-top: 1vh;
}

.main-buttons {
  margin-left: 1vh;
  margin-right: 1vh;
}

@media screen and (max-width: 600px) {
  .register-grid {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .login-form {
    width: 70%;
  }
}

@media screen and (max-width: 640px) {
  .login-form {
    width: 100%;
  }
}
