#root {
  min-height: 100vh;
}

.main-footer {
  min-height: 15vh;
  margin-top: auto;
  background-color: #575d60;
  text-align: center;
}

.footer-text {
  padding-top: 2vh;
  color: white;
}

a {
  color: white;
  text-decoration: none;
  background-color: none;
}

a:hover {
  color: white;
  text-decoration: none;
  background-color: none;
}
