.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.napis {
  padding-right: 2vh;
  margin-top: 1.9rem;
}

.search-spinner {
  justify-content: center;
  cursor: default;
}

.bar {
  width: 450px;
  z-index: 999;
  margin-bottom: 3vh;
  margin-right: 6vw;

  .search-input {
    position: absolute;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
    z-index: 999;

    input,
    input:focus {
      height: 55px;
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      border-radius: 5px;
      padding: 0 60px 0 20px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    }

    ::placeholder {
      color: #301a4b;
    }

    .result-box {
      max-height: 280px;
      width: 450px;
      overflow-y: auto;
      z-index: 999;

      li {
        list-style: none;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 3px;

        &:hover {
          background: #efefef;
        }
      }
    }
  }
}

// @media screen and (max-width: 1200px) {
//   .napis {
//     padding-right: initial;
//     margin-top: initial;
//   }

//   .bar {
//     margin-right: initial;
//   }
// }

@media screen and (max-width: 600px) {
  .search-bar {
    display: block;
  }

  .napis {
    padding-right: initial;
    margin-top: initial;
  }

  .bar {
    margin-right: initial;
  }

  .bar {
    .search-input {
      width: 95%;
    }
    .result-box {
      width: initial;
    }
  }
}

// .resultsbox {
//   display: flex;
//   z-index: 1;
//   border: 2px solid #ffffff;
//   border-top: none;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
// }

// .results {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 80%;
// }

// .result {
//   color: #301a4b;
//   margin-bottom: 3vh;
// }

// .separatorline {
//   width: 100%;
//   height: 1px;
//   background: black;
// }
