.alert {
  position: absolute;
  top: 12%;
  font-weight: 600;
  z-index: 1;
  border: none;
}

.alert-success {
  background: #59acec;
  color: white;
}

.long-alert {
  position: absolute;
  top: 12%;
  left: 20%;
  font-weight: 600;
  z-index: 999999;
  border: none;
}
