.adcontainer {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.banner {
  width: 90%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
}

@media screen and (max-width: 600px) {
  .adcontainer {
    margin-top: 5rem;
  }
}
