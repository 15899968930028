* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  // background: rgb(255, 255, 255);
  // background: linear-gradient(
  //   69deg,
  //   rgba(255, 255, 255, 0.15) 40%,
  //   rgba(89, 172, 224, 0.99) 100%
  // );
  background: url("https://static.naroci-se.com/ozadje.svg");
  background-size: 100%;
  overflow-x: hidden;
}

.mobilemenu {
  list-style: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-self: center;
}

ul {
  padding-left: 0;
}

.mobilelink {
  margin-top: 3vh;
  text-decoration: none;
}

section {
  overflow: hidden;
  min-height: calc(100vh - 8vh);
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

@import "./nav";
@import "./searchbar";
@import "./grid";
@import "./footer";
@import "./login";
@import "./offers";
@import "./account";
@import "./alerts";
@import "./bigad";
@import "./pagination";
@import "./filters";
