nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 3vh;
  margin: 0rem 1rem;
}

.nav {
  align-items: center;
}

.logo {
  margin-top: 2vh;
  font-weight: bold;
}

.link {
  margin: 2rem;
  color: #734158;
}

.mobileicons {
  display: flex;
  align-items: center;
}

.mobilelink {
  cursor: pointer;
}

.initalsinnavbar {
  margin-left: 2vw;
}

.navigation-button {
  cursor: pointer;
}

.navlinks {
  align-items: center;
}

.navbutton {
  width: 140%;
  height: 3rem;
  background: transparent linear-gradient(127deg, #ffffff66 0%, #ffffff0d 100%)
    0% 0% no-repeat padding-box;
  border: none;
  border-radius: 5px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  color: #301a4b;
  font-weight: bold;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  font-size: 1.5rem;
}

.navbutton-active {
  width: 140%;
  height: 3rem;
  background: rgba($color: #ffffff, $alpha: 1);
  border: none;
  border-radius: 5px;
  color: #734158;
  font-weight: bold;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  font-size: 1.5rem;
}

.placeholder-div {
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
  }

  .nav-link {
    color: black;
  }
}

@media screen and (max-width: 600px) {
  nav {
    margin: 1rem 1rem;
  }
}
