.account-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90vh;

  .account-box {
    height: 80%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f6f7;

    .user-short {
      width: 100%;
      height: 25%;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      border-radius: 3px;

      h3 {
        padding: 0;
        margin: 0 1rem;
      }
    }

    .user-long {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f6f7;
      border-radius: 3px;

      .form {
        display: flex;
        flex-direction: row;

        input {
          margin: 0.5rem;
          padding: 0.5rem;
          border: none;
          border-radius: 2px;
        }

        p {
          margin: 0;
          padding: 0;
          font-weight: bold;
        }

        .form-left,
        .form-right {
          display: flex;
          flex-direction: column;
          margin: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .account-container {
    margin-bottom: 2rem;
    .account-box {
      width: 90%;
      height: 100%;
      .user-short {
        height: 50%;
      }
      .user-long {
        .form {
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .account-container {
    margin-bottom: 2rem;
    .account-box {
      width: 90%;
      height: 100%;
      .user-short {
        height: 50%;
      }
      .user-long {
        .form {
          flex-direction: column;
        }
      }
    }
  }
}
